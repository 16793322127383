/**
 * Created by jacob.mendt@pikobytes.de on 19.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, {useState, useCallback} from "react";
import {useRecoilState} from "recoil";
import cloneDeep from "lodash.clonedeep";
import debounce from "lodash.debounce";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
    createStyles,
    makeStyles,
    FormControl,
    Input,
    InputAdornment,
    FormHelperText,
    Slider,
    Typography,
} from "@material-ui/core";
import {filterTreeLayerState} from "../structs/atoms";
import {FilterTypes} from "../utils/utils";
import FilterIcon from "@material-ui/icons/Tune";

const useStyles = makeStyles((theme) => {
    return createStyles({
        root: {
            padding: theme.spacing(2, 2, 2, 0),
            "& .MuiInputLabel-shrink": {
                display: "none"
            }
        },
        sliderFilterContainer: {
            padding: theme.spacing(4, 2, 2, 2),
            "& > *": {
                margin: theme.spacing(0.5),
            },
        },
        inputFilterContainer: {
            padding: theme.spacing(2, 0),
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '25ch',
        },
        filterHeader: {
            color: theme.palette.grey.c700,
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
        },
        filterIcon: {
            marginRight: theme.spacing(1)
        },
        descriptiveText: {
            fontSize: 12,
            lineHeight: '16px',
            color: theme.palette.grey.c700,
        }
    });
});

export default function FilterRange(props) {
    const {
        description,
        defaultValue,
        disabled = false,
        id,
        label,
        unit,
        valueRange,
    } = props;
    const classes = useStyles();
    const [filterTreeLayer, setFilterTreeLayer] = useRecoilState(filterTreeLayerState);
    const useSlider = Math.abs(valueRange[1] - valueRange[0]) <= 100;
    const [inputValues, setInputValues] = useState(defaultValue)

    //
    // Dispatch filter update
    //
    const dispatchFilterUpdate = useCallback((newValue, currentFilterTreeLayer) => {
            const newFilterTreeLayer = Object.assign({}, cloneDeep(currentFilterTreeLayer), {
                [id]: {
                    type: FilterTypes.RANGE,
                    value: [...newValue],
                },
            });
            setFilterTreeLayer(newFilterTreeLayer);
        }, [id, setFilterTreeLayer]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceDispatchFilterUpdate = useCallback(
        debounce((newValue, currentFilterTreeLayer) => {
            dispatchFilterUpdate(newValue, currentFilterTreeLayer);
        }, 2500),
        [dispatchFilterUpdate]
    );

    //
    // Handler
    //

    // Handle change committed by the slider and dispatch it
    const handleChangeCommittedSlider = (event, newValue) => {
        dispatchFilterUpdate(newValue, filterTreeLayer);
    };

    // Handle change by the input fields
    const handleChangeInput = (newInputValues) => {
        if (newInputValues[0] >= 0 && newInputValues[1] >= 0) {
            setInputValues(newInputValues);
            debounceDispatchFilterUpdate(newInputValues, filterTreeLayer);
        }
    }

    return (
        <div className={classes.root}>
            <Typography variant="h5" component="h6" className={classes.filterHeader}>
                <FilterIcon className={classes.filterIcon}/>
                {label}
            </Typography>
            <Typography variant="body1" component="p" className={classes.descriptiveText}>{description}</Typography>
            {
                useSlider && (
                    <div className={classes.sliderFilterContainer}>
                        <Slider
                            color="secondary"
                            disabled={disabled}
                            min={valueRange[0]}
                            max={valueRange[1]}
                            defaultValue={[...inputValues]}
                            onChangeCommitted={handleChangeCommittedSlider}
                            valueLabelDisplay="on"
                            aria-labelledby={`Auswahl des ${label}`}
                            getAriaValueText={(value) => `${label} ${value}`}
                        />
                    </div>
                )
            }
            {
                !useSlider && (
                    <div className={classes.inputFilterContainer}>
                        <FormControl className={clsx(classes.margin, classes.withoutLabel, classes.textField)}>
                            <Input
                                color="secondary"
                                id={`${id}-${label}-input-start`}
                                disabled={disabled}
                                value={inputValues[0]}
                                onBlur={() => dispatchFilterUpdate(inputValues, filterTreeLayer)}
                                onChange={(e) => handleChangeInput([
                                    parseInt(e.target.value),
                                    inputValues[1],
                                ])}
                                endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
                                aria-describedby={`${id}-${label}-helper-text-start`}
                                inputProps={{
                                    'aria-label': label,
                                }}
                                type="number"
                            />
                            <FormHelperText id={`${id}-${label}-helper-text-start`}>{label}</FormHelperText>
                        </FormControl>
                        <FormControl className={clsx(classes.margin, classes.withoutLabel, classes.textField)}>
                            <Input
                                color="secondary"
                                id={`${id}-${label}-input-end`}
                                disabled={disabled}
                                value={inputValues[1]}
                                onBlur={() => dispatchFilterUpdate(inputValues, filterTreeLayer)}
                                onChange={(e) => handleChangeInput([
                                    inputValues[0],
                                    parseInt(e.target.value),
                                ])}
                                endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
                                aria-describedby={`${id}-${label}-helper-text-end`}
                                inputProps={{
                                    'aria-label': label,
                                }}
                                type="number"
                            />
                            <FormHelperText id={`${id}-${label}-helper-text-end`}>{label}</FormHelperText>
                        </FormControl>
                    </div>
                )
            }
        </div>
    )
}

FilterRange.propTypes = {
    description: PropTypes.string,
    defaultValue: PropTypes.arrayOf(PropTypes.number).isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    unit: PropTypes.string,
    valueRange: PropTypes.arrayOf(PropTypes.number),
}