/**
 * Created by jacob.mendt@pikobytes.de on 23.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, {useRef} from "react";
import PropTypes from "prop-types";
import {
    Chip,
    createStyles,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => {
    return createStyles({
        root: {

        },
        rootPopover: {
            marginLeft: -theme.spacing(0.5),
        },
        popover: {
            padding: theme.spacing(1)

        }
    });
});

export default function InputChip(props) {
    const {
        clickable,
        disabled,
        filter,
        id,
        label,
        onDelete,
    } = props;
    const classes = useStyles();
    const ref = useRef();

    return (
        <React.Fragment key={id}>
            <Chip className={classes.root}
                  ref={ref}
                  clickable={clickable}
                  color="secondary"
                  label={label}
                  onDelete={onDelete}
                  style={{
                      backgroundColor: filter.color,
                  }}
                  disabled={disabled}
            />
        </React.Fragment>
    )
}

InputChip.propTypes = {
    clickable: PropTypes.bool,
    disabled: PropTypes.bool,
    featureCounts: PropTypes.object,
    filter: PropTypes.shape({
        color: PropTypes.string,
        id: PropTypes.string,
        value: PropTypes.string,
    }),
    id: PropTypes.string,
    label: PropTypes.string,
    onDelete: PropTypes.func,
}