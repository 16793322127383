/**
 * Created by jacob.mendt@pikobytes.de on 26.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx"
import {createStyles, makeStyles, Typography} from "@material-ui/core";
import IconNatureOutlined from "@material-ui/icons/NatureOutlined";

const useStyles = makeStyles((theme) => createStyles({
    root: {
        margin: theme.spacing(2, 0, 1, 8),
        padding: theme.spacing(1, 0, 1, 1),
        display: "flex",
        alignItems: "baseline",
        borderLeft: '1px solid',
        borderLeftColor: theme.palette.primary.dark
    },
    subtitle: {
        paddingLeft: theme.spacing(1),
        flex: 1,
    },
    icon: {
        fontSize: 32,
        alignSelf: "center",
        marginRight: theme.spacing(1)
    }
}));

export default function TitleFeature(props) {
    const {
        className = "",
        title,
        subtitle = undefined
    } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, className)}>
            <IconNatureOutlined color="secondary" className={classes.icon}/>
            <Typography variant="h2" component="h2">{title}
                {subtitle !== undefined && (
                    <Typography variant="subtitle1" component="p" className={classes.subtitle}>/ ID: {subtitle}</Typography>)}
            </Typography>

        </div>
    )
}

TitleFeature.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}