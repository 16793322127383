/**
 * Created by jacob.mendt@pikobytes.de on 04.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, {useEffect, useState} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    Divider,
    Drawer,
    makeStyles,
    isWidthUp,
    withWidth, CircularProgress,
} from "@material-ui/core";
import ButtonToggle from "../components/ButtonToggle";
import ButtonClose from "../components/ButtonClose";
import {isDrawerOpenState, isLoadingState, selectedFeatureState} from "../structs/atoms";
import {themeColors} from "./theme"
import Logo from "../_images/logoBaumCloud.svg";
import {ReactComponent as LogoIoer} from "../_images/logoIoer.svg";
import {ReactComponent as LogoPikobytes} from "../_images/logoPikobytes.svg";

export const drawerWidth = 500;
const useStyles = makeStyles((theme) => {
    return {
        root: {
            width: '100%',
            height: '100%',
            display: 'flex',
        },
        divider: {
            margin: theme.spacing(0, 0.75),
        },
        drawer: {
            width: drawerWidth,
            [theme.breakpoints.only('xs')]: {
                width: '100%',
            },
            flexShrink: 0,
            zIndex: 1,
        },
        drawerPaper: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
            width: drawerWidth,
            [theme.breakpoints.only('xs')]: {
                width: '98%',
            }
        },
        drawerInner: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        drawerOff: {
            zIndex: 0,
            pointerEvents: 'none',
        },
        header: {
            padding: theme.spacing(2),
        },
        imgLogo: {
            height: 50,
            [theme.breakpoints.up('sm')]: {
                height: 65
            }
        },
        navigation: {
            position: 'absolute',
            top: theme.spacing(4),
            right: theme.spacing(2),
            [theme.breakpoints.only('xs')]: {
                top: theme.spacing(2.5)
            }
        },
        backNavigationButton: {
            margin: theme.spacing(0, 2, -3, 0.75),
        },
        contentDrawer: {
            position: 'absolute',
            inset: '0 0 0 0',
        },
        containerContent: {
            flexGrow: 1,
            backgroundColor: theme.palette.primary.dark,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.only('xs')]: {
                marginLeft: '-100%',
            }
        },
        containerDrawer: {
            position: 'absolute',
            inset: '90px 0 40px 0',
            flexGrow: 1,
            overflowX: 'hidden',
            overflowY: 'auto',
            [theme.breakpoints.up('sm')]: {
                inset: '110px 0 40px 0'
            }
        },
        toggleButton: {
            position: 'absolute',
            top: theme.spacing(3),
            left: theme.spacing(4),
            zIndex: 1,
            [theme.breakpoints.only('xs')]: {
                top: theme.spacing(2),
                left: theme.spacing(3)
            }
        },
        footer: {
            position: 'absolute',
            left: 0,
            bottom: 0,
            right: 0,
            background: 'white',
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            textAlign: 'center',
            '& a': {
                color: themeColors.grey.c900,
                fontWeight: 400,
                fontSize: 14,
                lineHeight: '16px',
                margin: theme.spacing(0, 1),
                opacity: .6,
                '&:hover': {
                    opacity: 1
                }
            },
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(1),
            }
        },
        footerLogoIoer: {
            position: 'relative',
            height: 45,
            width: 36,
            overflow: 'hidden',
            margin: theme.spacing(1),
            display: 'inline-block',
            '& svg': {
                position: 'relative',
                top: 5,
                height: '110%',
                width: 'auto',
            },
            [theme.breakpoints.up('sm')]: {
                height: 40,
                width: 'auto',
                overflow: 'visible',
                '& svg': {
                    top: 0,
                    height: '100%'
                }
            }
        },
        footerLogoPikobytes: {
            position: 'relative',
            height: 45,
            width: 32,
            overflow: 'hidden',
            margin: theme.spacing(1),
            display: 'inline-block',
            '& svg': {
                position: 'relative',
                height: '100%',
                width: 'auto',
            },
            [theme.breakpoints.up('sm')]: {
                height: 40,
                width: 'auto',
                overflow: 'visible',
                '& svg': {
                    height: '90%'
                }
            }
        },
        progress: {
            position: 'absolute',
            inset: 0,
            background: 'rgba(255,255,255,0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    };
});

export function DefaultLayout(props) {
    const {
        className,
        content,
        backNavigationButton = "",
        drawerContent,
        width,
    } = props;
    const classes = useStyles();
    const [, setSize] = useState([window.innerWidth, window.innerHeight]);
    const [drawerOpen, setDrawerOpen] = useRecoilState(isDrawerOpenState);
    const behaveMobile = !isWidthUp(process.env.REACT_APP_LAYOUT_BREAK, width);
    const isLoading = useRecoilValue(isLoadingState);
    const isSelectedFeature = useRecoilValue(selectedFeatureState);

    //
    // Handler
    //

    // Toggle drawer state
    const handleToggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    }

    //
    // Effects
    //

    // Mount / unmount lifecycle effect
    useEffect(() => {
        // Detecting resize
        const handleResize = (e) => {
            setSize([window.innerWidth, window.innerHeight]);
            setDrawerOpen(true);
        }

        // Add resize behavior
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [setDrawerOpen]);

    return (
        <div className={clsx(classes.root, className)}>
            <Drawer
                className={clsx(classes.drawer, !drawerOpen ? classes.drawerOff : "")}
                anchor="left"
                open={drawerOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    hideBackdrop: true
                }}
            >
                <div className={classes.drawerInner}>
                    <div className={classes.contentDrawer}>
                        <div className={classes.header}>
                            <img className={classes.imgLogo} src={Logo} title="BaumCloudViewer" alt="Logo eines grünen Markers auf einer Karte mit Schriftzug"/>
                        </div>
                        <div className={classes.navigation}>
                            {behaveMobile && (
                                <ButtonClose onClick={handleToggleDrawer}/>)}
                        </div>
                        <Divider className={classes.divider}/>
                        <div className={classes.containerDrawer}>
                            {isSelectedFeature && (
                                <div className={classes.backNavigationButton}>{backNavigationButton}</div>)}
                            {drawerContent}
                        </div>
                        <div className={classes.footer}>
                            <div>
                                <a
                                    href={process.env.REACT_APP_LINK_IOER}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.footerLogoIoer}
                                    title="Leibniz-Institut für ökologische Raumentwicklung e. V."
                                >
                                    <LogoIoer/>
                                </a>
                                <a
                                    href={process.env.REACT_APP_LINK_PIKOBYTES}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.footerLogoPikobytes}
                                    title="Pikobytes GmbH"
                                >
                                    <LogoPikobytes/>
                                </a>
                            </div>
                            <div>
                                <a
                                    href={process.env.REACT_APP_LINK_INFO}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >Info</a>
                                <a
                                    href={process.env.REACT_APP_LINK_IMPRESSUM}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >Impressum</a>
                                <a
                                    href={process.env.REACT_APP_LINK_PRIVACY}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >Datenschutz</a>
                            </div>
                        </div>
                        {isLoading && (
                            <div className={classes.progress}><CircularProgress color="secondary" size={100}/></div>
                        )}
                    </div>
                </div>
            </Drawer>

            <div className={clsx(classes.containerContent)}>
                {(behaveMobile && !drawerOpen) && (
                    <ButtonToggle className={classes.toggleButton} onClick={handleToggleDrawer}/>)}
                {content}
            </div>
        </div>
    )
}

DefaultLayout.propTypes = {
    className: PropTypes.string,
    content: PropTypes.element.isRequired,
    drawerContent: PropTypes.element.isRequired,
    navigation: PropTypes.element,
    width: PropTypes.string,
}

DefaultLayout.defaultProps = {
    className: "",
    content: ""
}

export default withWidth()(DefaultLayout);