/**
 * Created by jacob.mendt@pikobytes.de on 17.01.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React from 'react';

export default function closeSidebar({fill = "#000000"}) {
    return (
        <svg viewBox="0 0 24 24" width={24} height={24}>
            <path fill={fill} d="M18 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm1 18c0 .6-.4 1-1 1H8c.6 0 1-.4 1-1v-6.2L7.2 12 9 10.2V4c0-.6-.4-1-1-1h10c.6 0 1 .4 1 1v16z"/>
            <path fill={fill} d="M13.9 16.2 9.8 12l4.1-4.2 1.5 1.4-2.8 2.8 2.8 2.8z"/>
        </svg>
    )
}
