/**
 * Created by jacob.mendt@pikobytes.de on 13.09.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React from "react";
import PropTypes from "prop-types";
import {createStyles, makeStyles} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => {
    return createStyles({
        root: {
            display: 'flex',
            width: '100%',
            height: 20,
            margin: theme.spacing(1.5, 0),
        },
        colorBlock: {
            height: 24,
            width: 24,
            borderRadius: 12,
        },
        labelBlock: {
            position: 'relative',
            lineHeight: '24px',
            flex: '1 1 auto',
            display: 'flex',
            justifyContent: 'space-between',
            '&:before': {
                position: 'absolute',
                bottom: 2,
                right: 0,
                left: 0,
                borderBottom: '1px dashed',
                borderBottomColor: theme.palette.grey.c300,
                content: '" "'
            }
        },
        featureLabel: {
            position: 'relative',
            fontWeight: 700,
            backgroundColor: 'white',
            padding: theme.spacing(0, 1, 0, 2)
        },
        featureCounts: {
            position: 'relative',
            color: theme.palette.grey.c400,
            background: 'white',
            paddingLeft: theme.spacing(1),
        },
        noFeatureCounts: {
            position: 'relative',
            width: '50%',
            background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);',
        }
    });
});

export default function LegendItem(props) {
    const {
        color,
        className = "",
        label,
        featureCounts,
        opacity = 1.0,
    } = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={clsx(classes.root, className)}>
                <div className={classes.colorBlock} style={{backgroundColor: color, opacity: opacity}}/>
                <div className={classes.labelBlock}>
                    <div className={classes.featureLabel}>{label}</div>
                    {(featureCounts !== null)
                        ? <div className={classes.featureCounts}>{featureCounts}</div>
                        : <div className={classes.noFeatureCounts}/>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

LegendItem.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    opacity: PropTypes.number,
    onColorChange: PropTypes.func,
}