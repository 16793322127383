/**
 * Created by jacob.mendt@pikobytes.de on 27.07.21.
 *
 * This file is subject to the terms and conditions defined in
 * file "LICENSE.txt", which is part of this source code package.
 */
import React, {useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import maplibre from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    createStyles,
    makeStyles,
    withWidth,
    isWidthUp,
} from "@material-ui/core";
import {
    isDrawerOpenState,
    filterMapExtentState,
    mapInstanceState,
    mapViewState,
    visibleMapExtentState,
} from "../structs/atoms";
// import ButtonInfo from "../components/ButtonInfo";
import ButtonToggleMap from "../components/ButtonToggleMap";
import TreeLayer from "../components/TreeLayer";
import {transformBoundsToExtent} from "../utils/utils";
import {drawerWidth} from "../layouts/DefaultLayout";
//import TestLayer from "../components/TestLayer";

const useStyles = makeStyles((theme) => {
    return createStyles({
        root: {
            display: 'block',
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: 0,
            marginRight: 0,
            width: '100%',
            height: '100%'
        },
        mapContainer: {
            width: '100%',
            height: '100%',
            '& .mapboxgl-ctrl': {
                backgroundColor: theme.palette.grey.c500,
                color: 'white',
                border: '1px solid white',
                boxShadow: '2px 3px 5px 0 rgba(0,0,0,0.2)',
                '& button .mapboxgl-ctrl-icon': {
                    filter: 'invert(100%) brightness(400%)'
                }
            },
        },
        buttonInfo: {
            position: 'absolute',
            [theme.breakpoints.down(process.env.REACT_APP_LAYOUT_BREAK)]: {
                left: theme.spacing(3),
            },
        },
        buttonToggleMap: {
            position: 'absolute',
            bottom: theme.spacing(2),
            left: drawerWidth + theme.spacing(3),
            [theme.breakpoints.down(process.env.REACT_APP_LAYOUT_BREAK)]: {
                left: theme.spacing(3),
            },
        }
    });
});

const satelliteSourceId = "source-satellite-overlay";
const satelliteLayerId = "layer-satellite-overlay";

export function MapView(props) {
    const {className = "", width} = props;
    const classes = useStyles();
    const mapRef = useRef(null);
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    const [map, setMapInstance] = useRecoilState(mapInstanceState)
    const [filterMapExtent, setFilterMapExtent] = useRecoilState(filterMapExtentState);
    const [mapView, setMapView] = useRecoilState(mapViewState);
    const [initialMapView] = useState(mapView);
    const isDrawerOpen = useRecoilValue(isDrawerOpenState);
    const setVisibleMapExtent = useSetRecoilState(visibleMapExtentState);
    const [activeBaseMap, setActiveBaseMap] = useState("default")

    //
    // Handler
    //

    const handleBaseMapToggle = (newActiveBaseMap) => {
        if (newActiveBaseMap === "default") {
            map.setLayoutProperty(satelliteLayerId, "visibility", "none");
        } else {
            map.setLayoutProperty(satelliteLayerId, "visibility", "visible");
        }
        ;

        setActiveBaseMap(newActiveBaseMap);
    };

    //
    // Effects
    //

    useEffect(() => {
        if (mapRef.current !== null) {
            const newMap = new maplibre.Map({
                attributionControl: true,
                container: mapRef.current,
                center: initialMapView.center,
                zoom: initialMapView.zoom,
                maxZoom: 18,
                pitch: 0,
                bearing: 0,
                style: process.env.REACT_APP_BASEMAP,
            });

            // Initialize and add a map navigation
            newMap.addControl(new maplibre.NavigationControl());

            //
            // Define map handler
            //

            // Called on every map move event
            const handleMapMove = (e) => {
                setFilterMapExtent(transformBoundsToExtent(e.target.getBounds()));
                setMapView({
                    center: e.target.getCenter().toArray(),
                    zoom: e.target.getZoom()
                })
            };

            // Called once on load
            const handleLoad = () => {
                // Adjust the maxZoom to support over zooming. The "meinGruen" source currently support
                // as max zoom level 15. But we want to support until 18.
                newMap.getSource(process.env.REACT_APP_BASEMAP_SOURCE).maxzoom = 15;

                if (process.env.REACT_APP_ARIEAL_MAP.length > 0) {
                    // Add arial layer
                    newMap.addSource(satelliteSourceId, {
                        type: 'raster',
                        tiles: [process.env.REACT_APP_ARIEAL_MAP],
                        tileSize: 512,
                        attribution: process.env.REACT_APP_ARIEAL_MAP_ATTRIBUTION,
                    });

                    newMap.addLayer({
                        id: satelliteLayerId,
                        type: 'raster',
                        source: satelliteSourceId,
                        layout: {
                            visibility: 'none',
                        },
                    });
                }


                setMapInstance(newMap);
            };

            // Handle resize of the map
            const handleMapResize = (e) => {
                setSize([window.innerWidth, window.innerHeight]);
            }

            // Attach eventlisters
            newMap.once("load", handleLoad);
            newMap.on("moveend", handleMapMove);

            // Add resize behavior
            window.addEventListener("resize", handleMapResize);

            // Debugger
            window.map = newMap;

            return () => {
                // Unmount perform the following clean ups.
                if (newMap !== undefined) {
                    newMap.off("moveend", handleMapMove);
                }

                setMapInstance(null);
            };
        }
    }, [initialMapView, setFilterMapExtent, setMapInstance, setMapView, setVisibleMapExtent]);

    // Effect hook, which detects if the map should resize
    useEffect(() => {
        if (map !== null) {
            map.resize();
        }
    }, [size, map]);

    // Effect hook, which calculate the current visible mapExtent
    useEffect(() => {
        const behaveMobile = !isWidthUp(process.env.REACT_APP_LAYOUT_BREAK, width);

        if (map === null) {
            return;
        }

        if (behaveMobile && !isDrawerOpen) {
            // The expectation is, that the map is seen on the hole screen
            setVisibleMapExtent(filterMapExtent);
        } else {
            // The expectation is, that parts of map are hidden by the drawer.
            const llc = map.unproject([drawerWidth, window.innerHeight]);
            const urc = map.unproject([window.innerWidth, 0]);
            const newVisibleMapExtent = [llc.lng, llc.lat, urc.lng, urc.lat];
            setVisibleMapExtent(newVisibleMapExtent);

        }
    }, [filterMapExtent, isDrawerOpen, map, setVisibleMapExtent, width]);

    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.mapContainer} ref={mapRef}/>
            {map !== null && (<TreeLayer map={map}/>)}
            {/*<ButtonInfo className={classes.buttonInfo} />*/}
            {
                (map !== null && process.env.REACT_APP_ARIEAL_MAP.length > 0) && (
                    <ButtonToggleMap activeBaseMap={activeBaseMap} className={classes.buttonToggleMap} onClick={handleBaseMapToggle}/>
                )
            }
            {/*{ map !== null && (<TestLayer map={map} />)}*/}
        </div>
    );
}

MapView.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.any,
    width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"])
};

export default withWidth()(MapView);
