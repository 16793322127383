/**
 * Created by jacob.mendt@pikobytes.de on 02.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file "LICENSE.txt", which is part of this source code package.
 */

// Default styling rules for the circle radius
export const DEFAULT_CIRCLE_RADIUS = [
    "interpolate",
    [
        "exponential",
        1.75
    ],
    ["zoom"],
    1, 1,
    5, 1.75,
    10, 2.5,
    22, 100
];

// Default styling rules for the circle color
export const DEFAULT_CIRCLE_COLOR = "#006FB4";

// Default styling for the circle
export const DEFAULT_CIRCLE_PAINT = {
    "circle-radius": DEFAULT_CIRCLE_RADIUS,
    "circle-color": DEFAULT_CIRCLE_COLOR,
    "circle-opacity": 1.0
}

// Styling for the hover
export const HOVER_CIRCLE_OUTER = {
    "circle-color": "transparent",
    "circle-stroke-color": DEFAULT_CIRCLE_COLOR,
    "circle-stroke-width": 1,
    "circle-radius": [
        "interpolate",
        [
            "exponential",
            1.75
        ],
        ["zoom"],
        1, 2,
        5, 3.5,
        10, 5,
        22, 200
    ],
}

// Default circle color for highlight features
export const DEFAULT_CIRCLE_COLOR_HIGHLIGHT = "#8CB91B";

// Color options for highlight features
export const HIGHLIGHT_COLORS_7 = [
    DEFAULT_CIRCLE_COLOR_HIGHLIGHT,
    "#5a5a5a",
    "#006eb4",
    "#4eb3d3",
    "#abdda4",
    "#fdae61",
    "#d53e4f"
];
export const HIGHLIGHT_COLORS_10 = [
    "#006eb4",
    "#2b8cbe",
    "#4eb3d3",
    "#7bccc4",
    "#a8ddb5",
    "#58a884",
    "#e08213",
    "#fdb863",
    "#c2a5cf",
    "#9970ab",
];

export const DEFAULT_TREE_LAYER_PAINT_HIGHLIGHT = Object.assign({}, DEFAULT_CIRCLE_PAINT, {
    "circle-color": DEFAULT_CIRCLE_COLOR_HIGHLIGHT
});

export const DEFAULT_TREE_LAYER_PAINT_NOT_HIGHLIGHT = Object.assign({}, DEFAULT_CIRCLE_PAINT, {});
