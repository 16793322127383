/**
 * Created by jacob.mendt@pikobytes.de on 27.07.21.
 *
 * This file is subject to the terms and conditions defined in
 * file "LICENSE.txt", which is part of this source code package.
 */
import React, {useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import maplibregl from "maplibre-gl";
import uniqueId from "lodash.uniqueid";
import isEqual from "lodash.isequal";
import debounce from "lodash.debounce";
import { useSetRecoilState, useRecoilValue} from "recoil";
import {isWidthUp, withWidth} from "@material-ui/core";
import {
  currentDataViewState,
  filterTreeLayerState,
  forceCountUpdateState,
  isDrawerOpenState,
  isLoadingState,
  selectedFeatureState,
} from "../structs/atoms";
import {
  DEFAULT_TREE_LAYER_PAINT_HIGHLIGHT,
} from "../structs/styles";
import {
  buildCircleColorExpressionFromDataView,
  buildCircleOpacityExpressionFromDataView,
  buildFilterExpression,
  NullFilter,
} from "../utils/utils";

function getQueryPoints(map, point) {
  const { x, y } = point;
  const snapPx = 5;
  return map.getZoom() >= 8.5
    ? [x, y]
    : [
      [x - snapPx, y - snapPx],
      [x + snapPx, y + snapPx],
    ];
}

/**
 * Adds the source to the map
 * @param {maplibregl.Map} map
 * @param {string} sourceId
 * @param {string} layerId
 * @param {Object} style
 */
function addTreeLayerSource(map, sourceId) {
  // Add the source
  map.addSource(sourceId, {
    type: "vector",
    tiles: [process.env.REACT_APP_TREE_TILES],
    bounds: [4.67,46.9,15.61,55.38],
    maxzoom: parseInt(process.env.REACT_APP_TREE_SOURCE_MAX_ZOOM),
    minzoom: 0
  });
}

let currentHoverFeature = undefined;
export const updateHoverFeature = (map, sourceId, sourceLayerId, featureId) => {
  // If the sourceId is not found return immediately
  if (map === null || map.getSource(sourceId) === undefined) {
    return;
  }

  if (
      currentHoverFeature !== undefined &&
      currentHoverFeature !== featureId
  ) {
    map.setFeatureState(
        {
          source: sourceId,
          sourceLayer: sourceLayerId,
          id: currentHoverFeature,
        },
        { hover: false }
    );
  }

  if (featureId !== undefined) {
    if (currentHoverFeature !== featureId) {
      map.setFeatureState(
          {
            source: sourceId,
            sourceLayer: sourceLayerId,
            id: featureId,
          },
          { hover: true }
      );
      currentHoverFeature = featureId;
    }
  } else {
    currentHoverFeature = undefined;
  }
};

/**
 * Utility function for update the filter
 *
 * @param {string} layerId
 * @param {*} newFilter
 * @param {maplibregl.Map} map
 * @param {Function} setIsLoading
 * @param {Function} setForceCountUpdate
 */
const updateLayerFilter = (layerId, newFilter, map, setIsLoading, setForceCountUpdate) => {
  if (map.getLayer(layerId) !== undefined) {
    map.setFilter(
        layerId,
        !isEqual(newFilter, NullFilter) ? newFilter  : null
    );

    // For signaling loading / finish
    setIsLoading(true);

    const callbackHandler = (e) => {
      if (e.isSourceLoaded) {
        map.off("sourcedata", layerId, callbackHandler);
        setIsLoading(false);
        setForceCountUpdate(uniqueId());
      }
    }
    map.on("sourcedata", layerId, callbackHandler);

    // Never block longer then 5 seconds
    setTimeout(
        () => {
          setIsLoading(false);
          setForceCountUpdate(uniqueId());
        }, 5000
    );
  }
}

// Id of the TreeLayer
export const TreeLayerId = "layer-tree-1";
export const TreeLayerHighlightId = "layer-tree-highlight";
const sourceId = process.env.REACT_APP_TREE_SOURCE_ID;
const sourceLayerId = process.env.REACT_APP_TREE_SOURCE_DATA_ID;

export function TreeLayer(props) {
  const { map, width } = props;
  const setSelectedFeature = useSetRecoilState(selectedFeatureState);
  const filterTreeLayer = useRecoilValue(filterTreeLayerState);
  const setForceCountUpdate = useSetRecoilState(forceCountUpdateState);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const setDrawerIsOpen = useSetRecoilState(isDrawerOpenState);
  const currentDataView = useRecoilValue(currentDataViewState);

  // Create a debounce version of the updateLayerFilter function. We use debounce to prevent to
  // fast updates cyclies of the filter
  const debounceUpdateLayerFilter = useMemo(
      () => debounce(updateLayerFilter, 1000),
      [],
  );


  //
  // Handler
  //

  // Handler for map mouseleave / mouseout
  const handleMouseOut = useCallback((e) => {
    const map = e.target;
    // Reset the cursor style
    map.getCanvas().style.cursor = "";

    // Reset hover
    //updateHoverFeature(map, sourceId, sourceLayerId);
  }, []);

  // Handler for map mousemove
  const handleMouseMove = useCallback((e) => {
    const map = e.target;
    // Query features with a snapping effect if the zoom is smaller 8.5
    const features = map.queryRenderedFeatures(getQueryPoints(map, e.point), {
      layers: [TreeLayerHighlightId],
    });

    // Update the map cursor
    map.getCanvas().style.cursor = features.length > 0 ? "pointer" : "";

    // Update hover
    // if (features.length > 0 ) {
    //   updateHoverFeature(map, sourceId, sourceLayerId, features[0].id);
    // } else {
    //   updateHoverFeature(map, sourceId, sourceLayerId);
    // }
  }, []);

  // Handle map click / touch
  const handleMapClick = useCallback((e) => {
    const map = e.target;
    // Query features with a snapping effect if the zoom is smaller 8.5
    const features = map.queryRenderedFeatures(getQueryPoints(map, e.point), {
      layers: [TreeLayerHighlightId],
    });

    if (features.length > 0 && features[0].properties !== undefined) {
      setSelectedFeature(Object.assign({}, features[0].properties, {
        coordinates: features[0].geometry.coordinates
      }));

      // In case we are in a mobile view open the drawer
      if (!isWidthUp(process.env.REACT_APP_LAYOUT_BREAK, width)) {
        setDrawerIsOpen(true);
      }
    } else {
      setSelectedFeature(null);
    }
  }, [setDrawerIsOpen, setSelectedFeature, width]);

  //
  // Effects
  //

  // onMount and unMount lifecycle
  useEffect(() => {
    if (map.getSource(sourceId) === undefined || map.getLayer(TreeLayerId) === undefined) {
      addTreeLayerSource(
          map,
          sourceId,
      );

      // Add highlight layer
      map.addLayer({
        id: TreeLayerHighlightId,
        type: "circle",
        source: sourceId,
        "source-layer": sourceLayerId,
        paint: DEFAULT_TREE_LAYER_PAINT_HIGHLIGHT,
        layout: { visibility: "visible" },
      });

      // Register event listeners
      map.on("mousemove", handleMouseMove);
      map.on("mouseleave", handleMouseOut);
      map.on("mouseout", handleMouseOut);
      map.on("click", handleMapClick);
    }

    // Return unmount callback
    return () => {
      if (map !== undefined && map.getLayer(TreeLayerHighlightId)) {
        map.off("mousemove", handleMouseMove);
        map.off("mouseleave", handleMouseOut);
        map.off("mouseout", handleMouseOut);
        map.removeLayer(TreeLayerHighlightId);
        map.removeSource(sourceId);
      }
    }
  }, [map, handleMouseMove, handleMouseOut, handleMapClick]);

  // Effect hook for updating the painting of the layer
  useEffect(() => {
    if (map !== null) {
      const newCircleColorExpression = buildCircleColorExpressionFromDataView(currentDataView);
      const newCircleOpacityExpression = buildCircleOpacityExpressionFromDataView(currentDataView);

      if (!isEqual(JSON.stringify(newCircleColorExpression), JSON.stringify(map.getPaintProperty(TreeLayerHighlightId, "circle-color")))
      ) {
        map.setPaintProperty(TreeLayerHighlightId, "circle-color", newCircleColorExpression);
        map.setPaintProperty(TreeLayerHighlightId, "circle-opacity", newCircleOpacityExpression);
      }
    }
  }, [map, currentDataView]);

  // Effect hook for updating the filter of the layer
  useEffect(() => {
    if (map !== null) {
      const newFilter = buildFilterExpression(filterTreeLayer);

      // Update the filter, but only if it really changes
      if (
          !isEqual(JSON.stringify(map.getFilter(TreeLayerHighlightId)), JSON.stringify(newFilter))
      ) {
        debounceUpdateLayerFilter(
            TreeLayerHighlightId,
            !isEqual(newFilter, NullFilter) ? newFilter  : null,
            map,
            setIsLoading,
            setForceCountUpdate
        );
      }
    }
  }, [debounceUpdateLayerFilter, map, filterTreeLayer, setForceCountUpdate, setIsLoading]);

  return (
    <div style={{ display: "none" }} />
  )
}

TreeLayer.propTypes = {
  map: PropTypes.instanceOf(maplibregl.Map),
};

export default withWidth()(TreeLayer);