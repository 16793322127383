/**
 * Created by jacob.mendt@pikobytes.de on 18.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import { useRef, useEffect } from "react";
import xor from "lodash.xor";
import {DEFAULT_CIRCLE_COLOR, DEFAULT_CIRCLE_COLOR_HIGHLIGHT} from "../structs/styles";

export const FilterTypes = {
    INPUT: "input",
    INPUT_GROUP: "input-grouped",
    RANGE: "range",
};

export const NullFilter = ["in", "id"];

/**
 * Creates a hash string for a given mapExtent
 * @param {number[]} extent
 * @returns {string}
 */
export function hashMapExtent(extent) {
    return JSON.stringify(extent);
}

export function buildFilterExpression(filters) {
    if (Object.keys(filters).length === 0) {
        return NullFilter;
    }

    // Build filters
    const newFilters = [];

    // Sort keys, so that filters of type input are build first
    const filterKeys = Object.keys(filters);
    filterKeys.sort(
        (k1, k2) => {
            if (filters[k1].type === FilterTypes.INPUT) {
                return -1;
            } else if (filters[k1].type === FilterTypes.INPUT_GROUP && filters[k2].type !== FilterTypes.INPUT) {
                return -1;
            } else {
                return 1;
            }
        }
    );

    filterKeys.forEach(
        (key) => {
            const d = filters[key];
            if (d.type === FilterTypes.INPUT) {
                if (d.value.length > 0) {
                    newFilters.push(["match", ["get", key], d.value.map(v => v.value), true, false]);
                }
            } else if (d.type === FilterTypes.RANGE) {
                newFilters.push([
                    "all",
                    [">=", ["get", key], d.value[0]],
                    ["<=", ["get", key], d.value[1]]
                ]);
            } else if (d.type === FilterTypes.INPUT_GROUP) {
                if (d.value.length > 0) {
                    const groupedBy = {};
                    const newInputGroupFilter = [
                        "any",
                        ...d.value.map(
                            v => {
                                if (groupedBy[v.groupedBy] === undefined) {
                                    groupedBy[v.groupedBy] = [v.group];
                                } else {
                                    groupedBy[v.groupedBy].push(v.group);
                                }

                                return [
                                    "all",
                                    ["==", ["get", key], v.value],
                                    ["==", ["get", v.groupedBy], v.group],
                                ];
                            }
                        )
                    ];

                    // If we set for example the genus type "Acer" and "Carpinus" and only a species for "Acer", this would
                    // exclude also all "Carpinus" trees. To prevent this, we add an extra filter to this any expression
                    // for each genus, for which no species is defined.
                    Object.keys(groupedBy).forEach(
                        (k) => {
                            const rawValues = filters[k].value.map(v => v.value);
                            const missingValues = xor(rawValues, groupedBy[k]);
                            missingValues.forEach(
                                v => {
                                    newInputGroupFilter.push(
                                        ["==", ["get", k], v]
                                    )
                                }
                            )
                        }
                    );

                    newFilters.push(newInputGroupFilter);
                }

            } else {
                throw new Error(`The given filterType "${d.type}" is not supported by the expression builder.`)
            }
        }
    );

    // In case there is only a single new filter we return it raw. If there
    // are multiple filters we wrap them into an boolean expression
    if (newFilters.length === 1) {
        return newFilters[0];
    } else {
        return [
            "all",
            ...newFilters
        ]
    }
}

export function buildPaintColorExpression(filters = undefined, filterKey = undefined) {
    if (
        filters === undefined ||
        Object.keys(filters).length === 0 ||
        filterKey === undefined ||
        filters[filterKey] === undefined ||
        filters[filterKey].type !== FilterTypes.INPUT
    ) {
        return DEFAULT_CIRCLE_COLOR_HIGHLIGHT;
    }

    // Create new paintProperty
    const paintColorProperty = [
        "match",
        ["get", filterKey]
    ];
    filters[filterKey].value.forEach(
        d => {
            paintColorProperty.push(...[d.value, d.color])
        }
    );

    return paintColorProperty.length === 2
        ? DEFAULT_CIRCLE_COLOR_HIGHLIGHT
        : [
            ...paintColorProperty,
            DEFAULT_CIRCLE_COLOR_HIGHLIGHT
        ];
}

/**
 * Creates from a given data view a circle-color property
 * @params {{
 *     paint: Object,
 *     paintConf: Object,
 *     legend: *[][]
 * }} Data view object
 * @returns {Object} Paint object for a "circle-color"
 */
export function buildCircleColorExpressionFromDataView({ paint, paintConf, legend }) {
    if (paintConf === undefined && paint["circle-color"] !== undefined) {
        return paint["circle-color"];
    } else if (legend !== undefined && paintConf !== undefined && paintConf["type"] === "steps") {
        const steps = [];
        legend.forEach(
            (item, index) => {
                if (index === 0) {
                    steps.push(item[1]);
                } else {
                    steps.push(item[2]);
                    steps.push(item[1]);
                }
            }
        );

        return [
            "case",
            ["!=", ["get", paintConf["field"]], paintConf["noDataValue"]],
            [
                "step",
                ["get", paintConf["field"]],
                ...steps

            ],
            paintConf !== undefined ? paintConf.defaultColor : DEFAULT_CIRCLE_COLOR
        ]
    } else if (legend !== undefined && paintConf !== undefined && paintConf["type"] === "match") {
        const values = [];
        legend.forEach(
            item => {
                values.push(item[2]);
                values.push(item[1]);
            }
        );

        return [
            "match",
            ["get", "genus"],
            ...values,
            // Rule for the rest,
            paintConf !== undefined ? paintConf.defaultColor : DEFAULT_CIRCLE_COLOR
        ];
    }
}

/**
 * Creates from a given data view a circle-opacity property
 * @params {{
 *     paint: Object,
 *     paintConf: Object,
 *     legend: *[][]
 * }} Data view object
 * @returns {Object} Paint object for a "circle-opacity"
 */
export function buildCircleOpacityExpressionFromDataView({ paint, paintConf, legend }) {
    if (paintConf === undefined && paint["circle-color"] !== undefined) {
        return paint["circle-opacity"];
    } else if (legend !== undefined && paintConf !== undefined && paintConf["type"] === "steps") {
        return [
            "case",
            ["!=", ["get", paintConf["field"]], paintConf["noDataValue"]],
            1.0,
            0.25
        ]
    } else if (legend !== undefined && paintConf !== undefined && paintConf["type"] === "match") {
        return [
            "match",
            ["get", paintConf["field"]],
            legend.map(l => l[2]),
            1.0,
            0.25
        ]
    }
}

// "case",
//     ["boolean", ["feature-state", "hover"], false],
//     1.0,
//     0.15,

export function invertFilterExpression(filters) {
    return ["!", filters];
}

/**
 * Transform the bounds to extent.
 * @param bounds
 */
export function transformBoundsToExtent(bounds) {
    return [
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
        bounds.getNorth(),
    ];
}

// Hook for saving the previous value
export function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}