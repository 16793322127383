/**
 * Created by jacob.mendt@pikobytes.de on 26.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React from "react";
import PropTypes from "prop-types";
import {Typography, createStyles, makeStyles} from "@material-ui/core";
import FilterInput from "./FilterInput";
import FilterRange from "./FilterRange";

const useStyles = makeStyles((theme) => createStyles({
    divider: {
        margin: theme.spacing(0, 0.75, 2, 0.75),
    },
    helperText: {
        paddingTop: theme.spacing(2)
    }
}));

export default function Filter(props) {
    const {
        filterConfigurations,
        filterTreeLayer,
        isLoading,
    } = props;
    const classes = useStyles();
    const activeFilterConfigurations = filterConfigurations.filter(
        filterConfig => filterConfig.active === true,
    );

    return (
        <div>
            {
                activeFilterConfigurations.length === 0 && (
                    <Typography variant="body1" component="p" className={classes.helperText}>
                        Bitte wählen Sie zu erst einen Filter aus.
                    </Typography>
                )
            }
            {
                activeFilterConfigurations.map(
                    (filterConfig) => {
                        if (filterConfig.type === "input" || filterConfig.type === "input-grouped") {
                            return (
                                <React.Fragment key={filterConfig.id}>
                                    <FilterInput
                                        defaultValue={
                                            filterTreeLayer[filterConfig.id] !== undefined
                                                ? filterTreeLayer[filterConfig.id].value
                                                : []
                                        }
                                        enableColorPicker={filterConfig.type === "input"}
                                        filterType={filterConfig.type}
                                        groupedBy={filterConfig.groupedBy !== undefined ? filterConfig.groupedBy : undefined}
                                        groupValues={
                                            filterTreeLayer[filterConfig.groupedBy] !== undefined
                                                ? filterTreeLayer[filterConfig.groupedBy].value.map(
                                                    d => d.value
                                                )
                                                : []
                                        }
                                        description={filterConfig.description}
                                        disabled={isLoading}
                                        id={filterConfig.id}
                                        label={filterConfig.label}
                                        values={filterConfig.values}
                                    />
                                </React.Fragment>

                            )
                        } else if (filterConfig.type === "range") {
                            return (
                                <React.Fragment key={filterConfig.id}>
                                    <FilterRange
                                        description={filterConfig.description}
                                        defaultValue={
                                            filterTreeLayer[filterConfig.id] !== undefined
                                                ? filterTreeLayer[filterConfig.id].value
                                                : filterConfig.valueRange
                                        }
                                        disabled={isLoading}
                                        id={filterConfig.id}
                                        label={filterConfig.label}
                                        unit={filterConfig.unit}
                                        valueRange={filterConfig.valueRange}
                                    />
                                </React.Fragment>
                            )
                        } else {
                            return (
                                <React.Fragment key={filterConfig.id}>
                                    <Typography component="p" variant="body1">Filter type "{filterConfig.type}" not
                                        supported.</Typography>
                                </React.Fragment>
                            )
                        }
                    }
                )
            }
        </div>
    )
}

Filter.propTypes = {
    filterConfigurations: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            values: PropTypes.arrayOf(
                PropTypes.shape({
                    group: PropTypes.string.isRequired,
                    value: PropTypes.string.isRequired,
                }),
            ),
            valueRange: PropTypes.arrayOf(
                PropTypes.number,
            )
        })
    ),
    filterTreeLayer: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
}