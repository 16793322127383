/**
 * Created by jacob.mendt@pikobytes.de on 19.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React from "react";
import {useRecoilValue} from "recoil"
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    Chip,
    Typography,
    createStyles,
    makeStyles,
} from "@material-ui/core";
import {
    mapInstanceState,
    mapViewState, visibleMapExtentState
} from "../structs/atoms";
import {themeFonts} from "../layouts/theme"
import {TreeLayerHighlightId} from "./TreeLayer";

const useStyles = makeStyles((theme) => {
    return createStyles({
        root: {
            padding: theme.spacing(3, 2),

        },
        heading: {
            marginBottom: theme.spacing(1.5),
            fontFamily: themeFonts.robotoSlabBold.fontFamily,
            fontSize: 20,
            lineHeight: '20px',
            textTransform: 'uppercase',
            color: theme.palette.grey.c700
        },
        buttonContainer: {
            paddingTop: theme.spacing(3.5)
        },
        chip: {
            fontSize: 16,
            fontFamily: themeFonts.robotoMedium.fontFamily,
            fontWeight: 500,
            letterSpacing: 0.4,
            lineHeight: '24px',
            padding: theme.spacing(1.5, 1),
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(1),
            backgroundColor: theme.palette.secondary.dark,
            color: 'white',
            '&.Mui-disabled': {
                opacity: .3,
            }
        },
    });
});


export default function FilterInfo(props) {
    const {
        className = "",
    } = props;
    const classes = useStyles();
    const map = useRecoilValue(mapInstanceState);
    const mapView = useRecoilValue(mapViewState);
    const mapExtent = useRecoilValue(visibleMapExtentState);
    const textMsg = mapView.zoom < process.env.REACT_APP_TREE_SOURCE_MAX_ZOOM
        ? "Es wird nur ein Teil der Baumdaten dargestellt. Für die volle Detailtiefe und die Möglichkeit gefilterte Bäume herunterzuladen (Button \"selektierte Bäume\"), zoomen Sie bitte weiter in die Karte hinein."
        : "Es wird die volle Detailtiefe der Baumkatasterdaten dargestellt. Für den Download einer Selektion (Button \"selektierte Bäume\") deaktivieren sie bitte Ihren Popup-Blocker."

    //
    // Handler
    //

    const handleDownloadAll = () => {
        if (process.env.REACT_APP_DATA_DOWNLOAD.length > 0) {
            // Trigger the download
            const anchor = document.createElement("a");
            anchor.setAttribute("href", process.env.REACT_APP_DATA_DOWNLOAD);
            anchor.setAttribute("download", "all_trees.json");
            anchor.style.display = "none";
            document.body.appendChild(anchor);
            anchor.click();
            anchor.remove();
        } else {
            console.warn("Could not detect a registered download link.")
        }
    }

    const handleDownloadSelected = () => {
        if (map !== null) {
            // Extract highlight data from view and map it to GeoJSON
            const exportArea = [
                map.project([mapExtent[0], mapExtent[1]]),
                map.project([mapExtent[2], mapExtent[3]])
            ];
            const outputData = {
                type: "FeatureCollection",
                features: map.queryRenderedFeatures(exportArea, {
                    layers: [TreeLayerHighlightId],
                }).map(
                    (feature) => ({
                        type: "Feature",
                        properties: feature.properties,
                        geometry: feature.geometry
                    })
                )
            }

            // Trigger the download
            const anchor = document.createElement("a");
            anchor.setAttribute("href", "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(outputData)));
            anchor.setAttribute("download", "selected_trees.json");
            anchor.style.display = "none";
            document.body.appendChild(anchor);
            anchor.click();
            anchor.remove();
        }
    }

    return (
        <div className={clsx(classes.root, className)}>
            <Typography component="h5" variant="h5" className={classes.heading}>Download</Typography>
            <Typography variant="body1" component="p">
                <Typography component="span" style={{fontWeight: 'bold'}}>Hinweis:</Typography> {textMsg}
            </Typography>
            <div className={classes.buttonContainer}>
                <Chip
                    color="secondary"
                    className={classes.chip}
                    label="Alle Bäume"
                    title="Download aller Baumdaten als JSON-Datei"
                    onClick={handleDownloadAll}
                />
                <Chip
                    color="secondary"
                    className={clsx(classes.chip)}
                    label="Ausgewählte Bäume"
                    title="Download der in der Karte angezeigten Baumdaten als JSON-Datei"
                    disabled={mapView.zoom < process.env.REACT_APP_TREE_SOURCE_MAX_ZOOM}
                    onClick={handleDownloadSelected}
                />
            </div>
        </div>
    );
}

FilterInfo.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
};
