/**
 * Created by jacob.mendt@pikobytes.de on 02.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file "LICENSE.txt", which is part of this source code package.
 */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import isArray from "lodash.isarray";
import {
    Divider,
    Typography,
    createStyles,
    makeStyles,
} from "@material-ui/core";
import TitleFeature from "./TitleFeature";

const useStyles = makeStyles((theme) => {
    return createStyles({
        root: {
            padding: theme.spacing(3, 2, 3, 0.75),
        },
        record: {
            padding: theme.spacing(1.75, 2.25, 2.25, 3),
        },
        title: {
            marginBottom: theme.spacing(0.5)
        }
    });
});


export default function FeatureInfo(props) {
    const {
        className = "",
        feature,
    } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, className)}>
            <TitleFeature title={feature.name_botanic} subtitle={feature.id}/>
            {
                Object.keys(feature).map(
                    (propertyName, index) => {
                        const value = isArray(feature[propertyName])
                            ? feature[propertyName].join(", ")
                            : feature[propertyName];
                        return (
                            <React.Fragment key={index}>
                                {index !== 0 && (<Divider/>)}
                                <div className={clsx(classes.record)}>
                                    <Typography component="h6" variant="h6" className={classes.title}>{propertyName}</Typography>
                                    <Typography component="p" variant="body1">{value === -1 ? "NoData" : value}</Typography>
                                </div>
                            </React.Fragment>
                        )
                    }
                )
            }
        </div>
    );
}

FeatureInfo.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    feature: PropTypes.shape({
        credits: PropTypes.string,
        genus: PropTypes.string,
        id: PropTypes.number,
        license: PropTypes.string,
        name_german: PropTypes.string,
        name_original: PropTypes.string,
        tree_height: PropTypes.number,
        treetop_diam: PropTypes.number,
        year_of_planting: PropTypes.number,
    }).isRequired,
};
