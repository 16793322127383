/**
 * Created by jacob.mendt@pikobytes.de on 13.09.21.
 *
 * This file is subject to the terms and conditions defined in
 * file "LICENSE.txt", which is part of this source code package.
 */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {useRecoilState, useRecoilValue} from "recoil";
import {
    FormControl,
    MenuItem,
    Select,
    Typography,
    createStyles,
    makeStyles,
} from "@material-ui/core";
import cloneDeep from "lodash.clonedeep";
import LegendItem from "./LegendItem";
import {
    currentDataViewState,
    featureCountsState,
} from "../structs/atoms";
import {DataViews} from "../structs/dataviews";

const useStyles = makeStyles((theme) => {
    return createStyles({
        root: {},
        formControl: {
            margin: theme.spacing(1, 0),
            width: "calc(100% - 16px)",
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        desc: {
            padding: theme.spacing(1, 0),
            margin: "0 !important"
        },
        bottomDesc: {
            bottom: 0,
            position: "absolute",
        },
        legend: {
            padding: theme.spacing(1, 0),
        },
        legendItemNoData: {
            marginBottom: theme.spacing(3)
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
            position: "absolute",
            backgroundColor: "rgba(0,0,0,.35)"
        },
        backdropTitle: {
            margin: theme.spacing(0, 2),
        }
    });
});

export default function Legend(props) {
    const {
        className = "",
    } = props;
    const classes = useStyles();
    const [currentDataView, setCurrentDataView] = useRecoilState(currentDataViewState);
    const featureCounts = useRecoilValue(featureCountsState);

    //
    // Handler
    //

    const handleChange = (e) => {
        const newDataView = DataViews.find(
            c => c.id === e.target.value
        );

        // Update the internal category and dispatch the update
        setCurrentDataView(newDataView);
    };

    const handleColorChanges = (legendIndex, newColor) => {
        if (currentDataView.legend !== undefined) {
            const newLegend = cloneDeep(currentDataView.legend);
            newLegend[legendIndex][1] = newColor;
            setCurrentDataView(Object.assign({}, currentDataView, {
                legend: newLegend,
            }));
        } else {
            console.warn("Can olny change color if legends are defined for the current data view.")
        }
    }

    const handleDefaultColorChange = (newColor) => {
        if (currentDataView.paintConf !== undefined) {
            const newPaintConf = cloneDeep(currentDataView.paintConf);
            newPaintConf.defaultColor = newColor;
            setCurrentDataView(Object.assign({}, currentDataView, {
                paintConf: newPaintConf,
            }));
        } else {
            console.warn("Can olny change color if legends are defined for the current data view.")
        }
    }
    //
    // Effects
    //

    return (
        <div className={clsx(classes.root, className)}>
            <FormControl className={classes.formControl}>
                <Select
                    value={currentDataView.id}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{"aria-label": "Wähle Filter"}}
                >
                    {
                        DataViews.map(
                            (c) => (
                                <MenuItem value={c.id} key={c.id}>{c.label}</MenuItem>
                            )
                        )
                    }
                </Select>
            </FormControl>
            <Typography variant="body1" component="p" className={classes.desc}>{currentDataView.desc}</Typography>
            {
                currentDataView.legend !== undefined && (
                    <div className={classes.legend}>
                        {
                            currentDataView.paintConf.type === "steps" && (
                                <LegendItem
                                    key="default-color"
                                    color={currentDataView.paintConf.defaultColor}
                                    label="Fehlende Daten"
                                    featureCounts={
                                        featureCounts.dataViewId === currentDataView.id && featureCounts.noDataCount !== null
                                            ? featureCounts.noDataCount
                                            : null
                                    }
                                    opacity={0.25}
                                    onColorChange={(newColor) => handleDefaultColorChange(newColor)}
                                />
                            )
                        }
                        {
                            currentDataView.legend.map(
                                (item, index) => (
                                    <LegendItem
                                        key={index}
                                        color={item[1]}
                                        label={item[0]}
                                        featureCounts={
                                            featureCounts.dataViewId === currentDataView.id && featureCounts.legendItemCounts.length === currentDataView.legend.length
                                                ? featureCounts.legendItemCounts[index]
                                                : null
                                        }
                                        onColorChange={(newColor) => handleColorChanges(index, newColor)}
                                    />
                                )
                            )
                        }
                        {
                            currentDataView.paintConf.type === "match" && (
                                <LegendItem
                                    key="default-color"
                                    color={currentDataView.paintConf.defaultColor}
                                    label={
                                        featureCounts.dataViewId === currentDataView.id && featureCounts.noDataCount !== null
                                            ? `Sonstige (${featureCounts.noDataCount})`
                                            : "Sonstige"
                                    }
                                    opacity={0.25}
                                    onColorChange={(newColor) => handleDefaultColorChange(newColor)}
                                />
                            )
                        }
                    </div>
                )
            }
        </div>
    );
}

Legend.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
};