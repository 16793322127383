/**
 * Created by jacob.mendt@pikobytes.de on 04.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { IconButton, makeStyles } from "@material-ui/core";
import SidebarIcon from "../_icons/openSidebar";

const useStyles = makeStyles(theme => ({
    root: {},
    button: {
        boxShadow: "2px 3px 5px 0 rgba(0,0,0,0.2)",
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.grey.c500,
        border: '1px solid white'
    }
}));

export default function ButtonToggle({ className,  onClick }) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)}>
            <IconButton
                className={classes.button}
                aria-label="Toggle Filter"
                edge="start"
                color="inherit"
                onClick={onClick}
            >
                <SidebarIcon fill={'white'} />
            </IconButton>
        </div>
    );
}

ButtonToggle.defaultProps = {
    className: "",
};

ButtonToggle.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};
