/**
 * Created by jacob.mendt@pikobytes.de on 13.09.21.
 *
 * This file is subject to the terms and conditions defined in
 * file "LICENSE.txt", which is part of this source code package.
 */
import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import {useRecoilState} from "recoil";
import clsx from "clsx";
import cloneDeep from "lodash.clonedeep";
import {
    createStyles,
    makeStyles,
    Checkbox,
    ClickAwayListener,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
    Popover,
    Typography,
} from "@material-ui/core";
import {
    ToggleButtonGroup,
    ToggleButton,
} from "@material-ui/lab";
import FilterIcon from "@material-ui/icons/Tune";
import LegendIcon from "@material-ui/icons/FormatListBulleted";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {filterTreeLayerState} from "../structs/atoms";
import {FilterTypes} from "../utils/utils";
import {themeFonts} from "../layouts/theme";

export const FilterViews = {
    Legend: "legend",
    Filter: "filter",
}

const useStyles = makeStyles((theme) => {
    return createStyles({
        root: {
            padding: theme.spacing(2,0),
        },
        heading: {
            margin: theme.spacing(1, 0)
        },
        rootPopover: {
            marginLeft: -theme.spacing(0.5),
        },
        popover: {
            padding: theme.spacing(1)
        },
        titlePopover: {
            padding: theme.spacing(3, 0, 0, 3)
        },
        formControl: {
            margin: theme.spacing(3),
        },
        toggleGroup: {
            display: 'flex'
        },
        toggleButton: {
            color: theme.palette.grey.c700,
            fontSize: 18,
            fontFamily: themeFonts.robotoSlabBold.fontFamily,
            fontWeight: 700,
            padding: theme.spacing(1, 2),
            flex: '1 1 auto',
            '&.Mui-selected': {
                color: 'white',
                backgroundColor: theme.palette.grey.c500,
                '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    color: 'white',
                }
            },
            '&:hover': {
                backgroundColor: theme.palette.secondary.light
            },
        },
        buttonIcon: {
            marginRight: theme.spacing(1)
        }

    });
});

export default function FilterTitle(props) {
    const {
        className = "",
        filterConfigurations,
        filterView,
        onChangeView = console.warn("#onChangeView is not implemented."),
        onChangeFilterConfigurations = console.warn("#onChangeFilterConfigurations is not implemented.")
    } = props;
    const classes = useStyles();
    const [isFilterOptionsOpen, changeOpenFilterOptions] = useState(false);
    const [filterTreeLayer, setFilterTreeLayer] = useRecoilState(filterTreeLayerState);
    const ref = useRef();
    const titleFilter = "Filter";
    const titleLegend = "Legende";
    const invTitle = filterView === FilterViews.Filter ? titleLegend : titleFilter;

    const handleToggleButton = (event, newFilterView) => {
        if (newFilterView !== undefined && newFilterView !== null) {
            onChangeView(newFilterView);
        }
    }

    // Handler for opening the popover
    const handleFilterOptionsOpen = () => {
        const filterIsActive = filterConfigurations.reduce(
            (acc, cur) => {
                return acc || cur.active
            },
            false,
        );

        if (filterView === FilterViews.Filter || !filterIsActive) {
            changeOpenFilterOptions(!isFilterOptionsOpen);
        }
    }

    const handleChange = (event) => {
        const newFilterConfigurations = filterConfigurations.map(
            filterConfig => {
                return filterConfig.id === event.target.name ||
                (event.target.name === 'species' && filterConfig.id === 'genus') ||
                (event.target.name === 'genus' && filterConfig.id === 'species')
                    ? Object.assign({}, filterConfig, {
                        active: event.target.checked
                    })
                    : filterConfig
            }
        );

        // Make sure to sync the filterTreeLayer
        const newFilterTreeLayer = cloneDeep(filterTreeLayer);
        let filterTreeLayerChanged = false;
        newFilterConfigurations.forEach(
            filterConfig => {
                if (newFilterTreeLayer[filterConfig.id] !== undefined && filterConfig.active === false) {
                    // In this case remove the filter from the current filterTreeLayer configuration
                    delete newFilterTreeLayer[filterConfig.id]
                    filterTreeLayerChanged = true;
                } else if (newFilterTreeLayer[filterConfig.id] === undefined && filterConfig.active === true && filterConfig.type === FilterTypes.RANGE) {
                    // In this case add the filter from the current filterTreeLayer configuration
                    newFilterTreeLayer[filterConfig.id] = {
                        type: FilterTypes.RANGE,
                        value: [...filterConfig.valueRange]
                    }
                    filterTreeLayerChanged = true;
                }
            }
        )

        if (filterTreeLayerChanged) {
            setFilterTreeLayer(newFilterTreeLayer);
        }

        // Dispatch new filter configuration
        onChangeFilterConfigurations(newFilterConfigurations);
    }

    return (
        <div className={clsx(classes.root, className)}>
            <ToggleButtonGroup className={classes.toggleGroup} value={filterView} exclusive onChange={handleToggleButton} aria-label={`Wechsle zu ${invTitle}`}>
                <ToggleButton className={classes.toggleButton} value={FilterViews.Legend} aria-label={`Button ${titleLegend}`}>
                    <LegendIcon className={classes.buttonIcon}/>
                    {titleLegend}
                </ToggleButton>
                <ToggleButton className={classes.toggleButton} value={FilterViews.Filter} aria-label={`Button ${titleFilter}`} onClick={handleFilterOptionsOpen} ref={ref}>
                    <FilterIcon className={classes.buttonIcon}/>
                    {titleFilter}
                    <ArrowDropDownIcon/>
                </ToggleButton>
                <Popover open={isFilterOptionsOpen}
                    anchorEl={ref.current}
                    classes={{
                        root: classes.rootPopover,
                        paper: classes.popover,
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <ClickAwayListener onClickAway={() => changeOpenFilterOptions(false)}>
                        <div>
                            <Typography variant="h6" component="h6" className={classes.titlePopover}>Folgende Filter verwenden:</Typography>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                    {
                                        filterConfigurations.filter(
                                            filterConfig => filterConfig.id !== 'species'
                                        ).map(
                                            filterConfig => (
                                                <FormControlLabel
                                                    key={filterConfig.id}
                                                    control={
                                                        <Checkbox checked={filterConfig.active} name={filterConfig.id} onChange={handleChange}/>}
                                                    label={filterConfig.id === 'genus'
                                                        ? 'Genus & Species'
                                                        : filterConfig.label
                                                    }
                                                />
                                            )
                                        )
                                    }
                                </FormGroup>
                                <FormHelperText>Aktivieren oder Deaktivieren Sie unterschiedliche Datenfilter.</FormHelperText>
                            </FormControl>
                        </div>
                    </ClickAwayListener>
                </Popover>
            </ToggleButtonGroup>
        </div>
    )
}

FilterTitle.propTypes = {
    className: PropTypes.string,
    filterConfigurations: PropTypes.arrayOf(
        PropTypes.shape({
            active: PropTypes.bool.isRequired,
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ).isRequired,
    filterView: PropTypes.oneOf([FilterViews.Filter, FilterViews.Legend]),
    onChangeView: PropTypes.func,
    onChangeFilterConfigurations: PropTypes.func,
}