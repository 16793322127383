/**
 * Created by jacob.mendt@pikobytes.de on 04.08.2021
 *
 * This file is subject to the terms and conditions defined in
 * file "LICENSE.txt", which is part of this source code package.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import {RecoilRoot} from "recoil";
import {CssBaseline, ThemeProvider} from "@material-ui/core";
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {theme} from "./layouts/theme";

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <RecoilRoot>
            <App />
        </RecoilRoot>
    </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
