/**
 * Created by jacob.mendt@pikobytes.de on 04.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "../_icons/closeSidebar";

const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: theme.palette.grey.c500,
        border: '1px solid white'
    }
}));

export default function ButtonClose({ className,  onClick }) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)}>
            <IconButton
                className={classes.button}
                aria-label="Toggle Filter"
                edge="start"
                color="inherit"
                onClick={onClick}
            >
                <CloseIcon style={{ width: 24, height: 24 }} fill={'white'} />
            </IconButton>
        </div>
    );
}

ButtonClose.defaultProps = {
    className: "",
};

ButtonClose.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};
