/**
 * Created by jacob.mendt@pikobytes.de on 04.08.2021
 *
 * This file is subject to the terms and conditions defined in
 * file "LICENSE.txt", which is part of this source code package.
 */
import React from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import DefaultLayout from "./layouts/DefaultLayout";
import ButtonBack from "./components/ButtonBack";
import MapView from "./views/MapView";
import FilterView from "./views/FilterView";
import FeatureInfo from "./components/FeatureInfo";
import Marker from "./components/Marker";
import {mapInstanceState, selectedFeatureState} from "./structs/atoms";


function App() {
    const [selectedFeature, setSelectedFeature] = useRecoilState(selectedFeatureState);
    const map = useRecoilValue(mapInstanceState);

    //
    // Handler
    //

    const handleClickBack = () => {
        setSelectedFeature(null);
    }

    return (
        <React.Fragment>
            <DefaultLayout
                backNavigationButton={
                    selectedFeature !== null
                        ? <ButtonBack onClick={handleClickBack}/>
                        : undefined
                }
                content={<MapView/>}
                drawerContent={
                    selectedFeature !== null
                        ? (<FeatureInfo feature={selectedFeature}/>)
                        : (<FilterView/>)
                }
            />
            {
                (map !== null && selectedFeature !== null) && (
                    <Marker map={map} coordinates={selectedFeature.coordinates}/>
                )
            }
        </React.Fragment>
    );
}

export default App;
