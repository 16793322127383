/**
 * Created by jacob.mendt@pikobytes.de on 17.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "axios";
import { csvParse } from "d3-dsv";
import uniq from "lodash.uniq";
import {
    Divider,
    createStyles,
    makeStyles,
} from "@material-ui/core";
import CountControl from "../components/CountControl";
import Filter from "../components/Filter";
import FilterInfo from "../components/FilterInfo";
import FilterTitle, { FilterViews } from "../components/FilterTitle";
import Legend from "../components/Legend";
import {
    filterConfigurationState,
    filterTreeLayerState,
    isLoadingState,
} from "../structs/atoms";

const useStyles = makeStyles((theme) => {
    return createStyles({
        root: {
            display: "block",
        },
        divider: {
            margin: theme.spacing(0, 0.75),
        },
        filterContainer: {
            padding: theme.spacing(3, 2),
        }
    });
});

export default function FilterView() {
    const classes = useStyles();
    const [filterConfigurations, setFilterConfigurations] = useRecoilState(filterConfigurationState);
    const [currentFilterView, setCurrentFilterView] = useState(FilterViews.Legend);
    const filterTreeLayer = useRecoilValue(filterTreeLayerState);
    const isLoading = useRecoilValue(isLoadingState);

    //
    // Effects
    //

    // Initial data fetching behavior
    useEffect(() => {
        const fetchMetadata = async () => {
            const [
                responseMetadata,
                responseValues,
            ] = await Promise.all([
                axios.get("/metadata.json"),
                axios.get(process.env.REACT_APP_DISTINCT_VALUES)
            ]);

            if (responseMetadata.status === 200 && responseValues.status === 200) {
                const newMetadata = responseMetadata.data;

                // Parse genus and species fields
                let valuesGenus = [];
                const valuesGroupedSpecies = [];
                csvParse(responseValues.data).forEach(
                    (d) => {
                        valuesGenus.push(d.genus);
                        valuesGroupedSpecies.push({
                            group: d.genus,
                            value: d.species
                        });
                    }
                );

                // Group the genus data
                valuesGenus = uniq(valuesGenus).map(
                    (value) => {
                        const firstLetter = value[0].toUpperCase();
                        return {
                            group: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                            value: value,
                        }
                    });

                // Set the values to the metadata
                if (newMetadata.genus !== undefined) {
                    newMetadata.genus.values = valuesGenus;
                }

                if (newMetadata.species !== undefined) {
                    newMetadata.species.values = valuesGroupedSpecies;
                }

                // Update the filter configuration
                const newFilterConfiguration = Object.values(newMetadata);
                setFilterConfigurations(newFilterConfiguration);
            }
        }

        if (filterConfigurations.length === 0) {
            fetchMetadata();
        }
    }, [filterConfigurations, setFilterConfigurations]);

    return (
        <div className={classes.root}>
            <FilterInfo />
            <Divider className={classes.divider} />
            <div className={classes.filterContainer}>
                <FilterTitle
                    filterView={currentFilterView}
                    filterConfigurations={filterConfigurations}
                    onChangeView={setCurrentFilterView}
                    onChangeFilterConfigurations={setFilterConfigurations}
                />
                { currentFilterView === FilterViews.Filter && (<Filter filterConfigurations={filterConfigurations} filterTreeLayer={filterTreeLayer} isLoading={isLoading} />) }
                { currentFilterView === FilterViews.Legend && (<Legend  /> )}
            </div>
            <CountControl />
        </div>
    )
}