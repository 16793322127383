/**
 * Created by jacob.mendt@pikobytes.de on 26.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    IconButton,
    Typography,
    makeStyles,
} from "@material-ui/core";
import BackIcon from "../_icons/Back";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 25,
        left: 30,
        marginLeft: theme.spacing(1),
        background: theme.palette.secondary.main,
        '&:hover': {
            background: theme.palette.secondary.light,
        }
    },
    backButtonLabel: {
        display: 'none'
    }
}));

/**
 * Back button with a label
 * @param props
 * @returns {*}
 * @constructor
 */
export default function ButtonBack(props) {
    const {
        className = "",
        label = "Filter",
        onClick,
    } = props;
    const classes = useStyles();
    return (
        <IconButton
            edge="start"
            color="inherit"
            aria-label="Schließe Auswahl"
            className={clsx(classes.root, className)}
            onClick={onClick}
            disableRipple={true}
            disableFocusRipple={true}
            size="small"
        >
            <BackIcon fill="white"/>
            <Typography component="p" variant="button" className={classes.backButtonLabel}>{label}</Typography>
        </IconButton>
    );
}

ButtonBack.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
};
