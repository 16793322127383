/**
 * Created by jacob.mendt@pikobytes.de on 17.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, {useCallback, useEffect, useState} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import cloneDeep from "lodash.clonedeep";
import PropTypes from "prop-types";
import {
    createStyles,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import uniqBy from "lodash.uniqby";
import uniqueId from "lodash.uniqueid";
import {
    featureCountsState,
    filterTreeLayerState,
    mapViewState,
} from "../structs/atoms";
import {DEFAULT_CIRCLE_COLOR_HIGHLIGHT} from "../structs/styles";
import {FilterTypes} from "../utils/utils";
import InputChip from "./InputChip";
import FilterIcon from "@material-ui/icons/Tune";

const useStyles = makeStyles((theme) => {
    return createStyles({
        root: {
            padding: theme.spacing(3, 0),
            "& .MuiInputLabel-shrink": {
                display: "none"
            }
        },
        filterContainer: {
            padding: theme.spacing(2, 2, 2, 0),
            margin: theme.spacing(0, -.5),
            "& > *": {
                margin: theme.spacing(0.5),
                fontWeight: 700,
            },
        },
        filterHeader: {
            color: theme.palette.grey.c700,
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
        },
        filterIcon: {
            marginRight: theme.spacing(1)
        },
        descriptiveText: {
            fontSize: 12,
            lineHeight: '16px',
            color: theme.palette.grey.c700,
        },
        selectedFilters: {
            backgroundColor: 'red'
        }
    });
});

export default function FilterInput(props) {
    const {
        description,
        defaultValue,
        disabled = false,
        enableColorPicker = true,
        filterType,
        groupedBy,
        groupValues,
        id,
        label,
        values,
    } = props;
    const classes = useStyles();
    const [value, setValue] = useState(null);
    const [options, setOptions] = useState([]);
    const [internalFilters, setInternalFilters] = useState({hash: uniqueId(), filters: defaultValue});
    const featureCounts = useRecoilValue(featureCountsState);
    const mapView = useRecoilValue(mapViewState);
    const [filterTreeLayer, setFilterTreeLayer] = useRecoilState(filterTreeLayerState);

    //
    // Utility
    //

    // Add a new filter
    const addFilter = (newFilter, filterType) => {
        const newFilters = uniqBy(
            [...internalFilters.filters, {
                color: DEFAULT_CIRCLE_COLOR_HIGHLIGHT,
                group: newFilter.group,
                groupedBy: groupedBy,
                id: id,
                value: newFilter.value
            }],
            "value"
        );

        // Set the internal filters
        setInternalFilters({
            filters: newFilters,
            hash: uniqueId(),
        });

        // Update the external filters
        setFilterTreeLayer(
            Object.assign({}, cloneDeep(filterTreeLayer), {
                [id]: {
                    type: filterType,
                    value: newFilters
                },
            })
        );
    }

    // Dispatch filter update
    const dispatchFilter = useCallback((newFilters, filterType) => {
        setInternalFilters({
            filters: newFilters,
            hash: uniqueId(),
        });

        // Update the external filters
        setFilterTreeLayer(
            Object.assign({}, cloneDeep(filterTreeLayer), {
                [id]: {
                    type: filterType,
                    value: newFilters
                },
            })
        );
    }, [filterTreeLayer, id, setFilterTreeLayer])

    //
    // Handler
    //

    // Handle a select of an value
    const handleOnChangeValue = (event, newValue) => {
        if (newValue !== null) {
            setValue(value);
            addFilter(newValue, filterType);
        }
    }

    // Handle key down event
    const handleKeyDown = (event) => {
        if (event.code === "Enter" && value !== null) {
            addFilter(value, filterType);
        }
    }

    // Handle remove of filter
    const handleRemoveFilter = (filterValue) => {
        const newFilters = internalFilters.filters.filter(f => f.value !== filterValue.value);
        dispatchFilter(newFilters, filterType);
    }

    // Handle change of filter
    const handleChangeFilter = (newFilter) => {
        const newFilters = internalFilters.filters.map(f => f.value === newFilter.value ? newFilter : f);
        dispatchFilter(newFilters, filterType);
    }

    //
    // Effects
    //

    // Update the options
    useEffect(() => {
        if (filterType === FilterTypes.INPUT) {
            // We expect that there is no grouping and therefor we do not have to filter
            // the values
            setOptions([...values]);
        } else if (filterType === FilterTypes.INPUT_GROUP) {
            const newValues = values.filter(
                d => groupValues.includes(d.group)
            );
            setOptions([...newValues]);
        }
    }, [filterType, groupValues, values]);

    // Make sure that the species filter are keeped in sync
    useEffect(() => {
        // If groupValues are undefined this behavior should be disabled
        if (filterType === FilterTypes.INPUT_GROUP) {
            const newFilters = internalFilters.filters.filter(
                f => groupValues.includes(f.group)
            );

            if (internalFilters.filters.length !== newFilters.length) {
                dispatchFilter(newFilters, filterType);
            }
        }
    }, [dispatchFilter, filterType, groupValues, internalFilters]);

    return (
        <div className={classes.root}>
            <Typography variant="h5" component="h6" className={classes.filterHeader}>
                <FilterIcon className={classes.filterIcon}/>
                {label}
            </Typography>
            <Typography variant="body1" component="p" className={classes.descriptiveText}>{description}</Typography>
            <Autocomplete
                id={`filter-${id}`}
                disabled={disabled}
                options={options.sort((a, b) => -b.group.localeCompare(a.group))}
                groupBy={(option) => option.group}
                getOptionLabel={(option) => option.value}
                renderInput={(params) => <TextField {...params} label={label} color="secondary" fullWidth={true}/>}
                onChange={handleOnChangeValue}
                onKeyDown={handleKeyDown}
            />
            <div className={classes.filterContainer}>
                {
                    internalFilters.filters.map(
                        filter => {
                            const count = featureCounts[filter.value] !== undefined
                                ? featureCounts[filter.value].count
                                : 0;
                            const countLabel = mapView.zoom >= process.env.REACT_APP_TREE_SOURCE_MAX_ZOOM && filterType === FilterTypes.INPUT
                                ? `(${count})`
                                : '';
                            const nameLabel = filterType === FilterTypes.INPUT
                                ? filter.value
                                : `${filter.group} ${filter.value}`
                            return (
                                <InputChip
                                    key={`${filter.value}_${internalFilters.hash}_${count}_${filter.color}`}
                                    clickable={enableColorPicker}
                                    filter={filter}
                                    label={`${nameLabel} ${countLabel}`}
                                    onColorChange={(newColor) => handleChangeFilter(Object.assign({}, filter, {color: newColor}))}
                                    onDelete={() => handleRemoveFilter(filter)}
                                    disabled={disabled}
                                />
                            )
                        }
                    )
                }
            </div>
        </div>
    )
}

FilterInput.propTypes = {
    description: PropTypes.string,
    defaultValue: PropTypes.arrayOf(
        PropTypes.shape({
            group: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    disabled: PropTypes.bool,
    enableColorPicker: PropTypes.bool,
    filterType: PropTypes.oneOf([FilterTypes.INPUT, FilterTypes.INPUT_GROUP]),
    groupedBy: PropTypes.string,
    groupValues: PropTypes.arrayOf(
        PropTypes.string,
    ),
    id: PropTypes.string,
    label: PropTypes.string,
    values: PropTypes.arrayOf(
        PropTypes.shape({
            group: PropTypes.string,
            value: PropTypes.string,
        })
    ),
}