/**
 * Created by jacob.mendt@pikobytes.de on 17.01.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React from 'react';

export default function Back({ fill = "#000000" }) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <defs>
        <polygon id="a" points="21 11 6.83 11 10.41 7.41 9 6 3 12 9 18 10.41 16.59 6.83 13 21 13"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="white">
          <use href="#a"/>
        </mask>
        <g fill={fill} fillOpacity="" mask="url(#b)" className="icon">
          <rect width="24" height="24"/>
        </g>
      </g>
    </svg>
  )
}
