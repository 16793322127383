/**
 * Created by jacob.mendt@pikobytes.de on 01.09.21.
 *
 * This file is subject to the terms and conditions defined in
 * file "LICENSE.txt", which is part of this source code package.
 */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import maplibre from "maplibre-gl";

import MarkerIcon from "../_images/marker-icon-target.png";
import MarkerShadowIcon from "../_images/marker-shadow.png";

export const MARKER_STYLES = {
    marker: {
        cursor: "pointer",
    },
    markerShadowIcon: {
        position: "absolute",
        marginLeft: -12.5,
        marginTop: -41,
        height: 41,
        width: 35,
        zIndex: 0,
    },
    markerDefaultIcon: {
        position: "absolute",
        marginLeft: -12.5,
        marginTop: -41,
        height: 41,
        width: 25,
        zIndex: 1,
    },
};

const useStyles = makeStyles((theme) => createStyles(MARKER_STYLES));

export default function Marker(props) {
    const { coordinates, map } = props;

    const classes = useStyles();
    const refMarkerEl = useRef(null);
    const [marker, setMarker] = useState(null);

    //
    // Effect section
    //

    // initialize marker
    useEffect(() => {
        if (refMarkerEl.current !== null) {
            // Create the marker
            const el = refMarkerEl.current

            // Create new maplibre marker
            const newMarker = new maplibre.Marker(el);

            if (coordinates !== undefined) {
                newMarker.setLngLat(coordinates).addTo(map);
            }

            setMarker(newMarker);

            // throw away marker after unmount
            return () => {
                newMarker.remove();
                setMarker(null);
            };
        }
    }, [map, coordinates]);

    // update coordinates when they change
    useEffect(() => {
        if (marker !== null && coordinates !== undefined) {
            marker.setLngLat(coordinates);
        }
    }, [map, marker, coordinates]);

    return (
        <div className={classes.marker}>
            <div ref={refMarkerEl}>
                <img src={MarkerShadowIcon} className={classes.markerShadowIcon} alt="Schatten des Marker"/>
                <img src={MarkerIcon} className={classes.markerDefaultIcon} alt="Marker"/>
            </div>
        </div>
    );
}

Marker.propTypes = {
    coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
    map: PropTypes.instanceOf(maplibre.Map).isRequired
}