/**
 * Created by jacob.mendt@pikobytes.de on 27.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, {useState, useRef, useEffect} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    IconButton,
    Popper,
    makeStyles, Typography,
} from "@material-ui/core";
import LayersIcon from "@material-ui/icons/Layers";
import {themeColors} from "../layouts/theme";
import MeinGruenImg from "../_images/defaultMap.jpg";
import ArialImg from "../_images/satelliteMap.jpg";

const useStyles = makeStyles(theme => {
    return {
        button: {
            backgroundColor: theme.palette.grey.c500,
            border: '1px solid white',
            boxShadow: '2px 3px 5px 0 rgba(0,0,0,0.2)',
            color: 'white',
            '&:hover': {
                backgroundColor: themeColors.grey.c400
            }
        },
        popperContent: {
            padding: theme.spacing(1, 1.5),
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            boxShadow: '2px 3px 5px 0 rgba(0,0,0,0.2)',
            marginLeft: theme.spacing(1)
        },
        elementMap: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            padding: theme.spacing(1, 0)
        },
        image: {
            borderRadius: '50%',
            height: 65,
            width: 65
        },
        imageActive: {
            border: '2px solid #fff',
            boxShadow: `0 0 0 3px ${themeColors.secondary.main}`
        },
        mapLabel: {
            fontSize: 12,
            marginTop: theme.spacing(.5),
        },
        textActive: {
            fontWeight: 'bold',
            color: theme.palette.secondary.dark
        }
    };
});

export default function ButtonToggleMap({activeBaseMap, className, onClick}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'toggle-basemaps-popper' : undefined;
    const ref = useRef(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    //
    // Effect
    //

    // Effect which handles that an outside click closes the popper
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setAnchorEl(null);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, setAnchorEl]);

    return (
        <div className={clsx(classes.root, className)}>
            <IconButton
                className={classes.button}
                aria-describedby={id}
                aria-label="Tausche Basiskarte"
                edge="start"
                onClick={handleClick}
            >
                <LayersIcon style={{width: 24, height: 24}}/>
            </IconButton>
            <Popper id={id} open={open} anchorEl={anchorEl} placement="right-end">
                <div className={classes.popperContent} ref={ref}>
                    <div className={classes.elementMap} onClick={() => onClick("default")}>
                        <img className={clsx(classes.image, activeBaseMap === "default" && classes.imageActive)} src={MeinGruenImg} alt="Kartenbild"/>
                        <Typography component="span" variant="h6" className={clsx(classes.mapLabel, activeBaseMap === "default" && classes.textActive)}>MeinGrün Karte</Typography>
                    </div>
                    <div className={classes.elementMap} onClick={() => onClick("satellite")}>
                        <img className={clsx(classes.image, activeBaseMap === "satellite" && classes.imageActive)} src={ArialImg} alt="Satellitenbild"/>
                        <Typography component="span" variant="h6" className={clsx(classes.mapLabel, activeBaseMap === "satellite" && classes.textActive)}>Satellit</Typography>
                    </div>
                </div>
            </Popper>
        </div>
    );
}

ButtonToggleMap.defaultProps = {
    className: "",
};

ButtonToggleMap.propTypes = {
    activeBaseMap: PropTypes.oneOf(["default", "satellite"]),
    className: PropTypes.string,
    onClick: PropTypes.func,
};