/**
 * Created by jacob.mendt@pikobytes.de on 13.09.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import {
    DEFAULT_TREE_LAYER_PAINT_HIGHLIGHT,
} from "./styles";

export const NODATA_VALUE = -1;

export const DefaultCategory = {
    id: "None",
    label: "Nicht gesetzt",
    desc: "Aktuell ist kein Filter gesetzt.",
    paint: DEFAULT_TREE_LAYER_PAINT_HIGHLIGHT,
    paintConf: undefined,
    legend: undefined,
};

export const TreeHeightCategory = {
    id: "tree_height",
    label: "Baumhöhe",
    desc: "Die Baumhöhe gibt an wie hoch der Baum vom Untergrund bis zur Kronenspitze ist (in m).",
    paint: undefined,
    paintConf: {
        defaultColor: "#006FB4",
        field: "tree_height",
        noDataValue: NODATA_VALUE,
        type: "steps",
    },
    legend: [
        ["kleiner 2m", "#caebcc", null],
        ["2 bis 5m","#9dcba1", 2],
        ["5 bis 10m","#71ab79", 5],
        ["10 bis 20m","#448c51", 10],
        ["größer 20m","#006d2c", 20]
    ]
}

export const TrunkCircum = {
    id: "trunk_circum",
    label: "Stammumfang",
    desc: "Der Stammumfang ist der Umfang des Stammes in einem Meter Höhe über dem Erdboden (in cm).",
    paint: undefined,
    paintConf: {
        defaultColor: "#006FB4",
        field: "trunk_circum",
        noDataValue: NODATA_VALUE,
        type: "steps",
    },
    legend: [
        ["kleiner 25", "#caebcc", null],
        ["25 bis 50cm","#9dcba1", 25],
        ["50 bis 100cm","#71ab79", 50],
        ["100 bis 200cm","#448c51", 100],
        ["größer 200cm","#006d2c", 200]
    ]
}

export const TreeTopDiam = {
    id: "treetop_diam",
    label: "Kronendurchmesser",
    desc: "Der Kronendurchmesser (auch Kronenbreite) ist der Durchmesser der Baumkrone (in m).",
    paint: undefined,
    paintConf: {
        defaultColor: "#006FB4",
        field: "treetop_diam",
        noDataValue: NODATA_VALUE,
        type: "steps",
    },
    legend: [
        ["kleiner 2m", "#caebcc", null],
        ["2 bis 5m","#9dcba1", 2],
        ["5 bis 10m","#71ab79", 5],
        ["10 bis 20m","#448c51", 10],
        ["größer 20m","#006d2c", 20]
    ]
}

export const Genus = {
    id: "genus",
    label: "Gattungen",
    desc: "Klassifizierung nach den 12 wichtigsten Gattungen von Stadtbäumen.",
    paint: undefined,
    paintConf: {
        defaultColor: "#006FB4",
        field: "genus",
        noDataValue: "Sonstige",
        type: "match",
    },
    legend: [
        ["Acer (Ahorn)", "#855C75", "Acer"],
        ["Aesculus (Kastanie)", "#D9AF6B", "Aesculus"],
        ["Betula (Birke)", "#AF6458", "Betula"],
        ["Fagus (Buche)", "#736F4C", "Fagus"],
        ["Fraxinus (Esche)", "#526A83", "Fraxinus"],
        ["Platanus (Platane)", "#625377", "Platanus"],
        ["Populus (Pappel)", "#68855C", "Populus"],
        ["Salix (Weide)", "#9C9C5E", "Salix"],
        ["Quercus (Eiche)", "#A06177", "Quercus"],
        ["Robinia (Robinie)", "#8C785D", "Robinia"],
        ["Tilia (Linde)", "#467378", "Tilia"],
        ["Ulmus (Ulme)", "#7C7C7C", "Ulmus"]
    ]
}

export const YearOfPlantingCategory = {
    id: "year_of_planting",
    label: "Pflanzjahr",
    desc: "Jahr in welchem der Baum gepflanzt wurde.",
    paint: undefined,
    paintConf: {
        defaultColor: "#006FB4",
        field: "year_of_planting",
        noDataValue: NODATA_VALUE,
        type: "steps",
    },
    legend: [
        ["vor 1875","#A16928", null],
        ["1875 bis 1900","#bd925a", 1875],
        ["1900 bis 1925","#d6bd8d", 1900],
        ["1925 bis 1950","#edeac2", 1925],
        ["1950 bis 1975","#b5c8b8", 1950],
        ["1975 bis 2000", "#79a7ac", 1975],
        ["seit 2000", "#2887a1", 2000]
    ]
}

export const DataViews = [
    DefaultCategory,
    TreeHeightCategory,
    TrunkCircum,
    TreeTopDiam,
    Genus
]