/**
 * Created by jacob.mendt@pikobytes.de on 04.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import { atom } from "recoil";
import { DataViews } from "./dataviews";

/**
 * Describe the current selected data view.
 * @type {RecoilState<{
 *  legend: *,
 *  paint: {
 *   "circle-color": string,
 *   "circle-opacity": number,
 *   "circle-radius": [string, [string, number], [string], number, number, number, number, number, number, number, number]
 *   },
 *   id: string,
 *   label: string,
 *   desc: string
 *   }
 * >}
 */
export const currentDataViewState = atom({
    key: "currentDataViewState",
    default: DataViews[1],
});

export const featureCountsState = atom({
    key: "featureCountsState",
    default: {
        dataViewId: null,
        totalCount: null,
        legendItemCounts: [],
        noDataCount: null,
    },
});

export const filterConfigurationState = atom({
    key: "filterConfigurationState",
    default: [],
});

export const filterMapExtentState = atom({
    key: "filterMapExtentState",
    default: null,
});

export const filterTreeLayerState = atom({
    key: "filterTreeLayerState",
    default: {}
});

export const filterTreeLayerGenusState = atom({
    key: "filterTreeLayerGenusState",
    default: []
});

export const forceCountUpdateState = atom({
    key: "forceCountUpdateState",
    default: "",
})

export const isDrawerOpenState = atom({
    key: "isDrawerOpenState",
    default: true,
})

export const isLoadingState = atom({
    key: "isLoadingState",
    default: false,
});

export const mapInstanceState = atom({
    key: "mapInstanceState",
    dangerouslyAllowMutability: true,
    default: null,
});

export const mapViewState = atom({
    key: "mapViewState",
    default: process.env.REACT_APP_INITIAL_MAP_VIEW !== undefined ? JSON.parse(process.env.REACT_APP_INITIAL_MAP_VIEW) : {
        center: [13.32, 52.52],
        zoom: 10.55,
    },
});

export const selectedFeatureState = atom({
    key: "selectedFeatureState",
    default: null,
});

export const visibleMapExtentState = atom({
    key: "visibleMapExtentState",
    default: [],
});
