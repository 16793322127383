/**
 * Created by jacob.mendt@pikobytes.de on 17.01.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React from 'react';

export default function openSidebar({fill = "#000000"}) {
    return (
        <svg viewBox="0 0 24 24" width={24} height={24}>
            <path fill={fill} d="M12.8 7.8 17 12l-4.2 4.2-1.4-1.4 2.8-2.8-2.8-2.8z"/>
            <path fill={fill} d="M19 14v6c0 .6-.4 1-1 1H8c.6 0 1-.4 1-1V4c0-.6-.4-1-1-1h10c.6 0 1 .4 1 1v6l1 1V4c0-1.1-.9-2-2-2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-7l-1 1z"/>
        </svg>
    )
}
