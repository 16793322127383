/**
 * Created by jacob.mendt@pikobytes.de on 19.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import React, {useEffect, useState} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import uniqueId from "lodash.uniqueid";
import {
    currentDataViewState,
    featureCountsState,
    forceCountUpdateState,
    mapInstanceState,
    visibleMapExtentState,
} from "../structs/atoms";
import { usePrevious } from "../utils/utils";
import { TreeLayerHighlightId } from "./TreeLayer";

export default function CountControl(props) {
    const [processId, setProcessId] = useState(null);
    const prevProcessId = usePrevious(processId);
    const setFeatureCounts = useSetRecoilState(featureCountsState);
    const map = useRecoilValue(mapInstanceState);
    const mapExtent = useRecoilValue(visibleMapExtentState);
    const forceCountUpdate = useRecoilValue(forceCountUpdateState);
    const currentDataView = useRecoilValue(currentDataViewState);

    // Trigger an update because of an external forceCountUpdate
    useEffect(() => {
        setProcessId(uniqueId());
    }, [forceCountUpdate]);

    // Trigger an update because of an map extent update
    useEffect(() => {
        setProcessId(uniqueId());
    }, [mapExtent]);

    // Trigger an update because the currentDataView changed
    useEffect(() => {
        setProcessId(uniqueId());
    }, [currentDataView])

    // Check if the count table should be updated
    useEffect(() => {
        if (
            processId !== prevProcessId &&
            currentDataView.legend !== undefined &&
            currentDataView.paintConf !== undefined &&
            mapExtent.length > 0 &&
            map !== null &&
            map.getZoom() >= process.env.REACT_APP_TREE_SOURCE_MAX_ZOOM
        ) {
            const newFeatureCounts = {
                dataViewId: currentDataView.id,
                totalCount: null,
                legendItemCounts: [],
                noDataCount: null,
            };
            const mapSearchArea = [
                map.project([mapExtent[0], mapExtent[1]]),
                map.project([mapExtent[2], mapExtent[3]])
            ];
            let featureCountsChanged = false;

            if (currentDataView.paintConf.type === "steps" && currentDataView.legend.length >= 2) {
                featureCountsChanged = true;

                // For every legend compute the couts
                currentDataView.legend.forEach((item, index) => {
                    const itemFilter = [
                        "all",
                        ["!=", ["get", currentDataView.paintConf.field], currentDataView.paintConf.noDataValue]
                    ];

                    if (index === 0) {
                        itemFilter.push(
                            ["<", ["get", currentDataView.paintConf.field], currentDataView.legend[index + 1][2]]
                        );
                    } else if (index < currentDataView.legend.length - 1) {
                        itemFilter.push(
                            [">=", ["get", currentDataView.paintConf.field], currentDataView.legend[index][2]]
                        );
                        itemFilter.push(
                            ["<", ["get", currentDataView.paintConf.field], currentDataView.legend[index + 1][2]]
                        );
                    } else if (index === currentDataView.legend.length - 1) {
                        itemFilter.push(
                            [">", ["get", currentDataView.paintConf.field], currentDataView.legend[index][2]]
                        );
                    }

                    newFeatureCounts.legendItemCounts.push(
                        map.queryRenderedFeatures(mapSearchArea, {
                            layers: [TreeLayerHighlightId],
                            filter: itemFilter
                        }).length
                    )
                });

                // Attach no data counts
                newFeatureCounts.noDataCount = map.queryRenderedFeatures(mapSearchArea, {
                    layers: [TreeLayerHighlightId],
                    filter: ["==", ["get", currentDataView.paintConf.field], currentDataView.paintConf.noDataValue]
                }).length
            }

            if (currentDataView.paintConf.type === "match" && currentDataView.legend.length >= 2) {
                featureCountsChanged = true;

                // For every legend compute the couts
                currentDataView.legend.forEach((item) => {
                    newFeatureCounts.legendItemCounts.push(
                        map.queryRenderedFeatures(mapSearchArea, {
                            layers: [TreeLayerHighlightId],
                            filter: ["match", ["get", currentDataView.paintConf.field], item[2], true, false]
                        }).length
                    )
                });

                // Attach no data counts
                newFeatureCounts.noDataCount = map.queryRenderedFeatures(mapSearchArea, {
                    layers: [TreeLayerHighlightId],
                    filter: ["!",
                        ["match", ["get", currentDataView.paintConf.field], currentDataView.legend.map(l => l[2]), true, false]
                    ]
                }).length
            }


            if (featureCountsChanged) {
                // Get Total feature counts
                newFeatureCounts.totalCount = map.queryRenderedFeatures(mapSearchArea, {
                    layers: [TreeLayerHighlightId],
                    filter: null
                }).length

                setFeatureCounts(newFeatureCounts);
            }
        } else if (
            map !== null &&
            map.getZoom() < process.env.REACT_APP_TREE_SOURCE_MAX_ZOOM
        ) {
            setFeatureCounts({
                dataViewId: null,
                totalCount: null,
                legendItemCounts: [],
                noDataCount: null,
            })
        }
    }, [currentDataView, map, mapExtent, processId, prevProcessId, setFeatureCounts])
    return (
        <div style={{ display: "none" }} />
    )
}