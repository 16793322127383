/**
 * Created by jacob.mendt@pikobytes.de on 04.08.21.
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import {createTheme} from '@material-ui/core/styles';
import RobotoMedium from '../_fonts/Roboto-Medium.ttf';
import RobotoMonoMedium from '../_fonts/RobotoMono-Medium.ttf';
import RobotoLight from '../_fonts/Roboto-Light.ttf';
import RobotoRegular from '../_fonts/Roboto-Regular.ttf';
import RobotoSlab from '../_fonts/RobotoSlab-Bold.ttf'

export const themeColors = {
    grey: {
        c100: 'rgb(0,0,0)',
        c200: 'rgb(228, 227, 227)',
        c300: 'rgb(203, 201, 201)',
        c400: 'rgb(178, 176, 176)',
        c500: 'rgb(152, 150, 150)',
        c600: 'rgb(127, 125, 125)',
        c700: 'rgb(102, 99, 99)',
        c800: 'rgb(76, 74, 74)',
        c900: 'rgb(51, 50, 50)',
    },
    primary: {
        light: '#ffffff',
        main: '#ffffff',
        dark: '#cccccc',
        contrastText: '#000000',
    },
    secondary: {
        light: '#c0eb54',
        main: '#8cb91b',
        dark: '#5a8900',
        contrastText: '#000000',
    },
};

export const themeFonts = {
    robotoMedium: {
        fontFamily: 'Roboto Medium',
        fontStyle: 'normal',
        fontWeight: 500,
        src: `url(${RobotoMedium}) format('truetype')`
    },
    robotoLight: {
        fontFamily: 'Roboto Light',
        fontStyle: 'normal',
        fontWeight: 300,
        src: `url(${RobotoLight}) format('truetype')`
    },
    robotoRegular: {
        fontFamily: 'Roboto Regular',
        fontStyle: 'normal',
        fontWeight: 400,
        src: `url(${RobotoRegular}) format('truetype')`
    },
    robotoMonoMedium: {
        fontFamily: 'RobotoMono Medium',
        fontStyle: 'normal',
        fontWeight: 500,
        src: `url(${RobotoMonoMedium}) format('truetype')`
    },
    robotoSlabBold: {
        fontFamily: 'RobotoSlab Bold',
        fontStyle: 'normal',
        fontWeight: 700,
        src: `url(${RobotoSlab}) format('truetype')`
    }
};

// In case we want to theme the application
export const theme = createTheme({
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [
                    themeFonts.robotoMedium,
                    themeFonts.robotoLight,
                    themeFonts.robotoRegular,
                    themeFonts.robotoSlabBold,
                    themeFonts.robotoMonoMedium,
                ],
            },
        },
        MuiTypography: {
            h1: {
                fontFamily: themeFonts.robotoLight.fontFamily,
                fontWeight: 300,
                fontSize: 30,
                letterSpacing: -0.5,
                lineHeight: '35px',
            },
            h2: {
                fontFamily: themeFonts.robotoSlabBold.fontFamily,
                fontWeight: 700,
                fontSize: 24,
                letterSpacing: -0.16,
                lineHeight: '30px',
            },
            h6: {
                color: themeColors.grey.c700,
                margin: 0,
                fontSize: 14,
                fontFamily: themeFonts.robotoMedium.fontFamily,
                fontWeight: 500,
                lineHeight: '18px',
                letterSpacing: 0.4,
            },
            subtitle1: {
                color: themeColors.grey.c700,
            },
            body1: {
                color: themeColors.grey.c900,
                margin: 0,
                fontSize: 14,
                fontFamily: themeFonts.robotoRegular.fontFamily,
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: 0.25,
            },
            button: {
                color: themeColors.grey.c700,
                fontFamily: themeFonts.robotoMedium.fontFamily,
                fontSize: 16,
                fontWeight: 500,
                letterSpacing: 0.4,
                lineHeight: '24px',
                display: 'inline-block',
                margin: 0,
            }
        }
    },
    palette: themeColors,
    typography: {}
});